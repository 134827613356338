import React from 'react';

type Props = {
	width?: number;
	height?: number;
	color?: string;
};

const SuspensionIcon = ({ width, height, color }: Props) => {
	return (
		<svg
			width={width || '21'}
			height={height || '21'}
			viewBox='0 0 21 21'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M10.3809 4.84111e-08C8.33716 -0.000197737 6.33928 0.605654 4.6399 1.74094C2.94052 2.87622 1.61595 4.48995 0.83372 6.37804C0.0514859 8.26613 -0.153285 10.3438 0.245303 12.3483C0.643892 14.3527 1.62794 16.194 3.07299 17.6392C4.51805 19.0844 6.35921 20.0686 8.36364 20.4674C10.3681 20.8662 12.4457 20.6616 14.3339 19.8795C16.2221 19.0975 17.8359 17.7731 18.9714 16.0738C20.1068 14.3745 20.7129 12.3767 20.7129 10.333C20.71 7.59359 19.6205 4.96719 17.6836 3.03003C15.7466 1.09288 13.1203 0.00317648 10.3809 4.84111e-08ZM10.3809 1.55C12.4381 1.55651 14.4267 2.29126 15.9939 3.624L3.67188 15.945C2.58955 14.6703 1.89554 13.1118 1.67217 11.4546C1.4488 9.79734 1.70545 8.11079 2.41166 6.59499C3.11788 5.07919 4.24403 3.79775 5.65654 2.90266C7.06906 2.00757 8.70866 1.53638 10.3809 1.545V1.55ZM17.0899 4.719C18.512 6.39236 19.2523 8.53968 19.1634 10.7339C19.0746 12.9282 18.1631 15.0086 16.6104 16.5615C15.0576 18.1144 12.9772 19.026 10.783 19.1151C8.58873 19.2041 6.44136 18.464 4.76788 17.042L17.0899 4.719Z'
				fill={color}
			/>
		</svg>
	);
};

export default SuspensionIcon;
