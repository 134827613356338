import React from 'react';

type Props = {
	width?: number;
	height?: number;
	color?: string;
};

const PhoneIcon = ({ width, height, color }: Props) => {
	return (
		<svg
			width={width || '16'}
			height={height || '27'}
			viewBox='0 0 16 27'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M3.38462 0C1.52629 0 0 1.52196 0 3.375V23.625C0 25.478 1.52629 27 3.38462 27H12.6154C14.4737 27 16 25.478 16 23.625V3.375C16 1.52196 14.4737 0 12.6154 0H3.38462ZM3.38462 1.84091H12.6154C13.4758 1.84091 14.1538 2.517 14.1538 3.375V23.625C14.1538 24.483 13.4758 25.1591 12.6154 25.1591H3.38462C2.52417 25.1591 1.84615 24.483 1.84615 23.625V3.375C1.84615 3.26775 1.85718 3.16279 1.8774 3.06219C2.01894 2.35799 2.63172 1.84091 3.38462 1.84091ZM4.61538 3.06818C3.77625 3.06818 3.07692 3.76552 3.07692 4.60227V14.4205C3.07692 15.2572 3.77625 15.9545 4.61538 15.9545H11.3846C12.2238 15.9545 12.9231 15.2572 12.9231 14.4205V4.60227C12.9231 3.76552 12.2238 3.06818 11.3846 3.06818H4.61538ZM4.92308 4.90909H11.0769V14.1136H4.92308V4.90909ZM4 18.4091C3.48923 18.4091 3.07692 18.8202 3.07692 19.3295C3.07692 19.8389 3.48923 20.25 4 20.25H5.53846V18.4091H4ZM6.76923 18.4091V20.25H9.23077V18.4091H6.76923ZM10.4615 18.4091V20.25H12C12.5108 20.25 12.9231 19.8389 12.9231 19.3295C12.9231 18.8202 12.5108 18.4091 12 18.4091H10.4615ZM4 21.4773C3.48923 21.4773 3.07692 21.8884 3.07692 22.3977C3.07692 22.907 3.48923 23.3182 4 23.3182H5.53846V21.4773H4ZM6.76923 21.4773V23.3182H9.23077V21.4773H6.76923ZM10.4615 21.4773V23.3182H12C12.5108 23.3182 12.9231 22.907 12.9231 22.3977C12.9231 21.8884 12.5108 21.4773 12 21.4773H10.4615Z'
				fill={color}
			/>
		</svg>
	);
};

export default PhoneIcon;
