import React from 'react';

type Props = {
	width?: number;
	height?: number;
	color?: string;
};

const TimeIcon = ({ width, height, color }: Props) => {
	return (
		<svg
			width={width || '24'}
			height={height || '23'}
			viewBox='0 0 24 23'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M0 0V23H24V0H14C14 1.07064 13.1172 1.91667 12 1.91667C10.8828 1.91667 10 1.07064 10 0H0ZM2 1.91667H8.5625C9.25781 3.05094 10.5352 3.83333 12 3.83333C13.4648 3.83333 14.7422 3.05094 15.4375 1.91667H22V21.0833H2V1.91667ZM12 5.75C8.14453 5.75 5 8.76351 5 12.4583C5 16.1532 8.14453 19.1667 12 19.1667C15.8555 19.1667 19 16.1532 19 12.4583C19 8.76351 15.8555 5.75 12 5.75ZM12 7.66667C14.7734 7.66667 17 9.80046 17 12.4583C17 15.1162 14.7734 17.25 12 17.25C9.22656 17.25 7 15.1162 7 12.4583C7 9.80046 9.22656 7.66667 12 7.66667ZM11 8.625V13.0273L9.3125 14.6745L10.6875 15.9922L13 13.806V8.625H11Z'
				fill={color}
			/>
		</svg>
	);
};

export default TimeIcon;
