import React from 'react';

type Props = {
	width?: number;
	height?: number;
	color?: string;
};

const VerificationIcon = ({ width, height, color }: Props) => {
	return (
		<svg
			width={width || '21'}
			height={height || '25'}
			viewBox='0 0 21 25'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M10.3169 0.000421236C10.1082 -0.00631047 9.90509 0.0678573 9.74988 0.207421C9.74988 0.207421 5.70188 3.77442 0.849875 3.77442C0.636529 3.776 0.432446 3.8618 0.282053 4.01313C0.131659 4.16446 0.0471329 4.36907 0.046875 4.58242V11.1924C0.046875 14.7924 1.71288 20.7174 9.97688 24.1984C10.0763 24.2403 10.183 24.2618 10.2909 24.2618C10.3987 24.2618 10.5055 24.2403 10.6049 24.1984C18.8699 20.7174 20.5349 14.7924 20.5349 11.1924V4.58342C20.5349 4.36886 20.4496 4.16309 20.2979 4.01137C20.1462 3.85965 19.9404 3.77442 19.7259 3.77442C14.8789 3.77442 10.8259 0.207421 10.8259 0.207421C10.6856 0.0811501 10.5055 0.00792365 10.3169 0.000421236ZM10.2909 1.84842C12.765 3.82543 15.7643 5.03392 18.9179 5.32442V11.1934C18.9179 14.2934 17.7279 19.2844 10.2909 22.5514C2.85388 19.2834 1.66388 14.2894 1.66388 11.1934V5.32442C4.81749 5.03392 7.81679 3.82543 10.2909 1.84842ZM14.3189 8.61942C14.1089 8.62548 13.9095 8.71299 13.7629 8.86342L8.94287 13.6834L6.81888 11.5594C6.74578 11.4767 6.65653 11.4099 6.55663 11.363C6.45673 11.3161 6.34829 11.2901 6.23798 11.2867C6.12767 11.2832 6.01782 11.3024 5.9152 11.343C5.81257 11.3836 5.71933 11.4447 5.64123 11.5227C5.56312 11.6007 5.50179 11.6938 5.46102 11.7964C5.42024 11.8989 5.40088 12.0087 5.40413 12.1191C5.40737 12.2294 5.43315 12.3379 5.47989 12.4378C5.52662 12.5378 5.59332 12.6272 5.67587 12.7004L8.37588 15.4004C8.52759 15.5521 8.73334 15.6373 8.94788 15.6373C9.16241 15.6373 9.36816 15.5521 9.51987 15.4004L14.9119 10.0084C15.0285 9.89474 15.1081 9.74849 15.1403 9.58882C15.1724 9.42915 15.1557 9.26349 15.0921 9.11352C15.0286 8.96354 14.9213 8.83624 14.7842 8.74826C14.6471 8.66028 14.4867 8.61572 14.3239 8.62042L14.3189 8.61942Z'
				fill={color}
			/>
		</svg>
	);
};

export default VerificationIcon;
