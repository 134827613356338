export const TODAY_LISTS = [
	{
		amount: '5500',
		status: 'pending',
		transaction_type: 'Airtime topup',
	},
	{
		amount: '5500',
		status: 'success',
		transaction_type: 'Data purchase',
	},
];

export const TOMORROW_LISTS = [
	{
		amount: '5500',
		status: 'pending',
		transaction_type: 'Data purchase',
	},
	{
		amount: '5500',
		status: 'success',
		transaction_type: 'Data purchase',
	},
];
