import React from 'react';

type Props = {
	color?: string;
	height?: number;
	width?: number;
};

const FilterIcon = ({ width, height, color }: Props) => {
	return (
		<svg
			width={width || '17'}
			height={height || '22'}
			viewBox='0 0 17 22'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M4.60802 10H12.056C12.1879 9.99997 12.3167 9.96086 12.4263 9.8876C12.5359 9.81435 12.6214 9.71024 12.6718 9.58844C12.7223 9.46664 12.7355 9.33262 12.7097 9.20331C12.684 9.07401 12.6206 8.95524 12.5274 8.862L8.80336 5.13801C8.67834 5.01303 8.5088 4.94282 8.33202 4.94282C8.15525 4.94282 7.98571 5.01303 7.86069 5.13801L4.13669 8.862C4.04348 8.95524 3.98001 9.07401 3.9543 9.20331C3.92859 9.33262 3.94179 9.46664 3.99224 9.58844C4.04268 9.71024 4.12811 9.81435 4.23772 9.8876C4.34732 9.96086 4.47619 9.99997 4.60802 10Z'
				fill={color || '#28536B'}
			/>
			<path
				d='M4.60802 12H12.056C12.1879 12 12.3167 12.0391 12.4263 12.1124C12.5359 12.1857 12.6214 12.2898 12.6718 12.4116C12.7223 12.5334 12.7355 12.6674 12.7097 12.7967C12.684 12.926 12.6206 13.0448 12.5274 13.138L8.80336 16.862C8.67834 16.987 8.5088 17.0572 8.33202 17.0572C8.15525 17.0572 7.98571 16.987 7.86069 16.862L4.13669 13.138C4.04348 13.0448 3.98001 12.926 3.9543 12.7967C3.92859 12.6674 3.94179 12.5334 3.99224 12.4116C4.04268 12.2898 4.12811 12.1857 4.23772 12.1124C4.34732 12.0391 4.47619 12 4.60802 12Z'
				fill={color || '#28536B'}
			/>
		</svg>
	);
};

export default FilterIcon;
